import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import TWEEN from '@tweenjs/tween.js';
import { targetElements, defaultProps } from "./data/scrollRevealConfig";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'; // 使用GLTFLoader


initScrollReveal(targetElements, defaultProps);
initTiltEffect();

const panels = document.querySelectorAll('.panel');

panels.forEach(panel => {
  panel.addEventListener('click', () => {
    removeActiveClasses();
    panel.classList.add('active');
  });
});

function removeActiveClasses() {
  panels.forEach(panel => {
    panel.classList.remove('active');
  });
}

let camera, scene, renderer;
const clock = new THREE.Clock();
let mixer;
let isUserInteracting = false;  // 用于追踪用户是否在进行交互
let autoRotateSpeed = 0.2;  // 自动旋转的速度

init();
animate();

function init() {
  const container = document.getElementById('glbCanvas'); // 直接获取已存在的canvas容器

  camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 2000);
  camera.position.set(450, 250, 350);

  scene = new THREE.Scene();
  // 添加照明
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);  // 增加方向光的强度

// 假设角色在场景的中心，即坐标(0, 0, 0)
// 设置光源位置在角色前方，稍微向上
  directionalLight.position.set(0, 100, 200);

// 将光源指向场景的中心，即角色的位置
  directionalLight.target.position.set(0, 0, 0);
  scene.add(directionalLight);
  scene.add(directionalLight.target); // 确保添加target到场景中，以便正确更新光源方向

  function adjustScaleBasedOnDevice(object) {
    // 假设小于768像素宽度视为移动设备
    const isMobile = window.innerWidth < 768;
    if (isMobile) {
      object.scale.set(100, 120, 100);
    } else {
      object.scale.set(170, 120, 170);
    }
  }

  const loader = new GLTFLoader();
  loader.load('https://wenhaowenhaowenhao.oss-cn-hangzhou.aliyuncs.com/robot_playground.glb', function(gltf) {
    const object = gltf.scene;
    object.position.y -= 70; // 直接将模型向下移动
    mixer = new THREE.AnimationMixer(object);
    const action = mixer.clipAction(gltf.animations[0]);
    action.play();
    object.traverse(function(child) {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
    adjustScaleBasedOnDevice(object); // 调用函数以根据设备类型调整缩放比例
    scene.add(object);
  }, undefined, function(error) {
    console.error(error);
  });

  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: container });
  renderer.gammaOutput = true;  // 确保输出是gamma校正的
  renderer.gammaFactor = 2.2;   // gamma因子，通常为2.2
  renderer.physicallyCorrectLights = true;  // 启用物理正确的光照模型
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(container.clientWidth, container.clientHeight);
  renderer.shadowMap.enabled = true;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 2; // 可能需要调整曝光以获得更亮的图像
//   //解决加载gltf格式模型颜色偏差问题
//   const texture = new THREE.TextureLoader().load('http://192.168.31.223:8080/resource/App_463079f714fabfed8fa762402d6a5cc5/textures/Spartan_Ear_Mat_normal.png');
//   renderer.outputEncoding = THREE.sRGBEncoding;
//   // If texture is used for color information, set colorspace.
//   texture.encoding = THREE.sRGBEncoding;
//   /**
//    * Textures
//    */
//   var loader1 = new THREE.TextureLoader();
//   const bakedTexture = loader1.load('http://192.168.31.223:8080/resource/App_463079f714fabfed8fa762402d6a5cc5/textures/Spartan_Ear_Mat_normal.png')
//   bakedTexture.flipY = false
//   bakedTexture.encoding = THREE.sRGBEncoding
//
// // UVs use the convention that (0, 0) corresponds to the upper left corner of a texture.
//   texture.flipY = false;

  const controls = new OrbitControls(camera, renderer.domElement);
  // controls.target.set(0, 100, 0);
  controls.update();
  // 监听用户交互事件
  controls.addEventListener('start', () => {
    isUserInteracting = true;  // 用户开始交互
  });

  controls.addEventListener('end', () => {
    isUserInteracting = false;  // 用户结束交互
  });
  const initAnimation = () => {
    // 保存原始摄像机位置
    const originalPosition = new THREE.Vector3().copy(camera.position);
    const originalLookAt = new THREE.Vector3(0, 0, 0); // 假设原始lookAt目标是场景中心，根据实际情况调整

    let initialAngle = 0; // 初始角度
    const radius = 450; // 假设摄像机距离中心的距离固定为450

    const orbitTween = new TWEEN.Tween({ angle: initialAngle, y: camera.position.y })
      .to({ angle: Math.PI * 2, y: originalPosition.y }, 6000) // 同时动画化角度和y坐标
      .easing(TWEEN.Easing.Cubic.InOut)
      .onUpdate((obj) => {
        // 更新摄像机的位置
        camera.position.x = radius * Math.cos(obj.angle);
        camera.position.z = radius * Math.sin(obj.angle) + 100;
        camera.position.y = obj.y; // 直接更新y坐标
        camera.lookAt(scene.position); // 始终朝向场景中心
      })
      .onComplete(() => {
        // 启动第二个Tween，平滑地回到原始位置
        const returnTween = new TWEEN.Tween(camera.position)
          .to({ x: originalPosition.x, y: originalPosition.y, z: originalPosition.z + 100 }, 3000) // 持续时间可以根据需要调整
          .easing(TWEEN.Easing.Cubic.InOut)
          .onUpdate(() => {
            camera.lookAt(originalLookAt); // 在回到原位的过程中，逐渐调整lookAt
          })
          .start(); // 开始第二段动画
      });

    orbitTween.start();
  };

  initAnimation();

  // 配置交互式光标
  renderer.domElement.style.cursor = 'grab';  // 默认光标
  renderer.domElement.addEventListener('mousedown', () => {
    renderer.domElement.style.cursor = 'grabbing';
  });
  renderer.domElement.addEventListener('mouseup', () => {
    renderer.domElement.style.cursor = 'grab';
  });
}

function animate() {
  requestAnimationFrame(animate);
  // 每次调用animate时更新时间差
  const delta = clock.getDelta();
  // 更新动画混合器
  if (mixer) {
    mixer.update(delta);
  }
  // 当用户未进行交互时，执行场景旋转动画
  if (!isUserInteracting) {
    scene.rotation.y += autoRotateSpeed * delta;
  }
  // 更新Tween动画
  TWEEN.update();
  // 渲染场景和相机
  renderer.render(scene, camera);
}
